@import "./text-ellipsis.scss";

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/BrandonText-Black.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/BrandonText-BlackItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 450;
  src: url("../assets/fonts/BrandonText-Bold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 450;
  src: url("../assets/fonts/BrandonText-BoldItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 420;
  src: url("../assets/fonts/BrandonText-Medium.ttf") format("opentype");
  // font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 420;
  src: url("../assets/fonts/BrandonText-MediumItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/BrandonText-Regular.ttf") format("opentype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/BrandonText-RegularItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 330;
  src: url("../assets/fonts/BrandonText-Light.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 330;
  src: url("../assets/fonts/BrandonText-LightItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/BrandonText-Thin.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/BrandonText-ThinItalic.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "UTM Times";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/UTM Times-Bold.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "UTM Times";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/UTM Times-Regular.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "iCielBC1676ModernMap";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/iCielBC1676ModernMap-Italic.ttf") format("opentype");
  font-display: swap;
}
