@import "react-datepicker/dist/react-datepicker.css";
.react-datepicker {
  background-color: var(--c-1) !important;
  border: none !important;
  padding: 1.8rem;
  border-radius: 0;
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: transparent;
      border: none;
      padding-bottom: 0;
      .react-datepicker__current-month {
        font-family: var(--font-family-secondary);
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 1.5625rem;
        padding-bottom: 1rem;
        // color: var(--primary-color);
      }
    }
  }
  .react-datepicker__day-name {
    @apply uppercase;
    font-family: var(--font-family);
    font-weight: 450;
    font-size: 12px;
    // line-height: 17px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 40px;
    line-height: 40px;
    margin: 0;
    overflow: hidden;
    outline: none;
  }
  .react-datepicker__day {
    border-radius: 0;
    transition: all 0.1s;
    &:hover {
      background-color: rgba(#2e281f, 0.05);
    }
    &.react-datepicker__day--selected {
      border-radius: 0;
      background-color: var(--black-1);
    }
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    :before {
      border: none;
    }
    background: url(/chevron-right.png) no-repeat;
    width: 32px;
    height: 32px;
    border: none;
    margin-top: 1.875rem;
    margin-right: 1.875rem;
  }
  .react-datepicker__navigation--previous {
    background: url(/chevron-left.png) no-repeat;
    margin-left: 1.875rem;
  }
  .react-datepicker__triangle {
    display: none;
  }
}
.pb-popper {
  overflow: hidden;
  animation: dropdown 500ms cubic-bezier(0.48, 0, 0.12, 1);
  animation-iteration-count: 1;
}
@keyframes dropdown {
  0% {
    max-height: 0px;
    // opacity: 0;
  }

  100% {
    max-height: 1000px;
    // opacity: 1;
  }
}
