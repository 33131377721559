body {
  .homePage {
    background-color: var(--bg-black-color);
  }
}
.home-swiper {
  .swiper-pagination-bullet {
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(--c-1);
    opacity: 0.35;
    margin: 0 0.75rem !important;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--c-1);
    opacity: 1;
  }
  .swiper-fraction {
    @apply absolute left-0 right-0 z-10 mx-auto;
    max-width: var(--innerWidth);
    padding: 0 var(--pad-inner-large);
    bottom: 4.6rem;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    letter-spacing: 0.1em;
    font-family: var(--font-family);
  }
  .swiper-pagination {
    @apply text-left mx-auto right-0;
    left: 8rem !important;
    max-width: var(--innerWidth);
    padding: 0 var(--pad-inner-large);
    bottom: 4.5rem !important;
  }
}

.modal-menu {
  [data-scroll],
  .ama-img {
    opacity: 1;
  }
  .main-menu {
    .head {
      padding: 0 var(--pad-inner);
      padding-top: 2rem;
    }
    .content {
      padding-top: 8.375rem;
      padding-left: var(--pad-inner);
    }
    .foot {
      padding-left: var(--pad-inner);
      padding-bottom: 2rem;
    }
  }
  .sub-menu {
  }
}
.modal-menu-mobile {
  background-color: var(--bg-black-color);
  @apply hidden;
  [data-scroll],
  .ama-img {
    opacity: 1;
  }
}
.menu-inner {
  padding-left: 2rem;
  padding-right: 2rem;
}
.level-2 {
  @apply absolute inset-0 w-full h-full hidden;
  background-color: var(--bg-black-color);
  [data-scroll],
  .ama-img {
    opacity: 1;
  }
}

@media (max-width: 576px) {
  .modal-menu-mobile {
    @apply flex;
  }
  .level-2 {
    @apply flex;
  }
}
