.ReactModal__Overlay {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  transition: opacity 400ms ease-in-out;
  z-index: 60;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  background: transparent !important;
  padding: 0;
  border: none !important;
}
.ReactModal__Overlay--before-close {
  .custom_image {
    transition-delay: 0ms;
    transform: translateY(100%);
  }
}
